const [w, d, l] = [window, document, location];

export default class Utility
{
    constructor() {
        this.init();
    }

    init() {

        document.addEventListener("DOMContentLoaded", function ()
        {
            const checkbox = document.getElementById("field_privacy");
            const links = document.querySelectorAll(".block-campaign__btnwrap a");

            // 初期状態でリンクを無効化
            toggleLinks(checkbox.checked);

            // チェックボックスの状態に応じてリンクの有効/無効を切り替える
            checkbox.addEventListener("change", function ()
            {
                toggleLinks(this.checked);
            });

            function toggleLinks(isChecked)
            {
                links.forEach(link =>
                {
                    if (isChecked)
                    {
                        link.classList.remove("disabled");
                        link.removeEventListener("click", preventClick);
                    } else
                    {
                        link.classList.add("disabled");
                        link.addEventListener("click", preventClick);
                    }
                });
            }

            function preventClick(event)
            {
                event.preventDefault();
                alert("個人情報取得に同意する必要があります。");
            }
        });

    }

    //URLによる読み込み分岐
    loader(loaderSwitch)
    {
        let path = location.pathname;
        loaderSwitch(path);
    }
}
